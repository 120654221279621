import { configureStore } from "@reduxjs/toolkit";

import authReducer, {
    tokenMiddleware,
} from "../features/authentication/authSlice";
import { apiSlice, rtkQueryErrorMiddleware } from "./api/apliSlice";

const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        auth: authReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            apiSlice.middleware,
            tokenMiddleware,
            rtkQueryErrorMiddleware
        ),
    devTools: true, // should false for staging and prod
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
