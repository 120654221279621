import styles from "./Collections.module.scss";

import { useEffect, useRef } from "react";

import thumbnail from "../../assets/images/thumbnail.png";
import { ITrainInfo } from "../train/interface";

interface IProps {
    collectionInfo: ITrainInfo;
}
const CollectionCard = ({ collectionInfo }: IProps) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const filePath = `${process.env.PUBLIC_URL}/videos/card_${collectionInfo.id}.mp4`;

    useEffect(() => {
        videoRef.current && videoRef.current.play();
    }, [filePath]);

    return (
        <div className={styles.collectionInfo}>
            <div key={collectionInfo.id}>
                <video
                    className={styles.cardInfo}
                    src={filePath}
                    ref={videoRef}
                    width='100%'
                    preload='auto'
                    autoPlay
                    playsInline
                    poster={thumbnail}
                />
            </div>
        </div>
    );
};

export default CollectionCard;
