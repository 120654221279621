import "./assets/styles/globals.scss";

import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { Route, Routes, useLocation } from "react-router-dom";

import theme from "./assets/styles/theme";
import MenuLayout from "./components/layout/MenuLayout";
import PrivateLayout from "./features/authentication/components/PrivateLayout";
import VerifyLogin from "./features/authentication/components/VerifyLogin";
import Collections from "./pages/CollectionsPage";
import Game from "./pages/GamePage";
import Home from "./pages/HomePage";
import Login from "./pages/LoginPage";
import Map from "./pages/MapPage";

function App() {
    const location = useLocation();
    const bgColor = location.pathname === "/login" ? "#f2e5d2" : "#A88956";

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {/* 因為登入頁要跟其他頁面不同顏色，所以額外用 Box 包起來 */}
            <Box minHeight='100dvh' bgcolor={bgColor}>
                <Routes>
                    <Route element={<VerifyLogin />}>
                        <Route path='/' element={<Home />} />
                        <Route element={<MenuLayout />}>
                            <Route path='/login' element={<Login />} />
                            <Route element={<PrivateLayout />}>
                                <Route path='/game' element={<Game />} />
                                <Route path='/map' element={<Map />} />
                                <Route
                                    path='/collections'
                                    element={<Collections />}
                                />
                            </Route>
                        </Route>
                    </Route>
                </Routes>
            </Box>
        </ThemeProvider>
    );
}

export default App;
