import { Outlet } from "react-router-dom";

import FullscreenBtn from "../button/FullscreenBtn";
import Header from "../header/Header";

const MenuLayout = () => {
    const userAgent = navigator.userAgent;

    return (
        <>
            <Header />
            <Outlet />
            {userAgent.includes("Android") ? <FullscreenBtn /> : <></>}
        </>
    );
};
export default MenuLayout;
