import type { Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { isRejectedWithValue } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { act } from "react-dom/test-utils";

import { postLogout } from "../../features/authentication/authSlice";
import { IApiRes } from "../../types/interfaces";
import { signOut } from "../firebase/firebaseAuth";
import { RootState } from "../store";

const baseUrl = process.env.REACT_APP_BACKEND_URL || "http://localhost:5005";

const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, api) => {
        const { getState } = api;
        const isLogin = (getState() as RootState).auth.isLogin;
        const token = localStorage.getItem("accessToken") || null;
        if (isLogin && token) {
            headers.set("Authorization", "Bearer " + token);
        }
        return headers;
    },
});

export const apiSlice = createApi({
    baseQuery: baseQuery,
    tagTypes: ["Auth", "Train", "Collection"],
    endpoints: () => ({}),
});

const checkIs401 = (httpStatus: number, res: IApiRes): boolean => {
    return (
        httpStatus === 401 &&
        res?.message_key === "TOKEN.EXPIRED" &&
        res?.status === 0
    );
};
export const rtkQueryErrorMiddleware: Middleware =
    (api: MiddlewareAPI) => (next) => async (action) => {
        // this project does not have refresh token , so just forcing user logout when access token is expired

        if (isRejectedWithValue(action)) {
            console.log("🐑 ~ fetch api error:", action?.payload);
            const { status, data } = action?.payload;
            if (checkIs401(status, data)) {
                api.dispatch(postLogout());
                await signOut();
            }
        }

        return next(action);
    };

/**
     * {
    "message": "Token is expired",
    "message_key": "TOKEN.EXPIRED",
    "status": 0
}
     */
