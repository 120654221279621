import { useEffect } from "react";

import { onAuthStateChanged } from "firebase/auth";
import { Outlet, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { auth } from "../../../services/firebase/firebaseAuth";
import {
    postLogin,
    postLogout,
    selectCurrentUser,
    selectIsLogin,
    setCurrentUser,
} from "../authSlice";
import { ICurrentUser } from "../interfaces";

const VerifyLogin = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isLogin = useAppSelector(selectIsLogin);

    const currentUser = useAppSelector(selectCurrentUser);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
            const accessToken = localStorage.getItem("accessToken");
            const refreshToken = localStorage.getItem("refreshToken");
            const accountId = localStorage.getItem("accountId");
            if (firebaseUser && accessToken && refreshToken && accountId) {
                let userInfo: Partial<ICurrentUser> = {
                    username: firebaseUser.displayName!,
                    email: firebaseUser.email!,
                    photoUrl: firebaseUser.photoURL!,
                    accountId: accountId,
                };
                dispatch(postLogin({ accessToken, refreshToken, accountId }));
                if (isLogin && !currentUser) {
                    dispatch(setCurrentUser(userInfo));
                }
            } else {
                //navigate("/login");
                //dispatch(postLogout());
            }
        });

        return () => unsubscribe();
    }, [dispatch, navigate, isLogin, currentUser]);

    return <Outlet />;
};
export default VerifyLogin;
