import styles from "./ResetGameBtn.module.scss";

import { useEffect, useState } from "react";

import { Box, Button, Modal, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Loader from "../../../components/loader/Loader";
import { useResetGameMutation } from "../trainApiSlice";

interface IProps {
    confirmCb: () => void;
}
const ResetGameBtn = ({ confirmCb }: IProps) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [resetGame, { isSuccess, isLoading }] = useResetGameMutation();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleResetGame = async () => {
        await resetGame();
        confirmCb();
    };

    useEffect(() => {
        if (isSuccess) {
            navigate("/login");
        }
    }, [isSuccess, navigate]);

    return (
        <div>
            <Loader isLoading={isLoading} />
            <Button className={styles.resetBtn} onClick={handleOpen}>
                遊戲重置
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box className={styles.modal}>
                    <Typography
                        id='modal-modal-title'
                        className={styles.title}
                        variant='h6'
                        component='h2'
                    >
                        確定要重製遊戲 ?
                    </Typography>
                    <Typography
                        id='modal-modal-description'
                        className={styles.text}
                    >
                        一旦重製，進度將重新開始
                    </Typography>
                    <Box className={styles.buttonsBox}>
                        <Button
                            variant='outlined'
                            className='button is-primary'
                            onClick={handleClose}
                        >
                            取消
                        </Button>
                        <Button
                            variant='contained'
                            className='button is-primary'
                            onClick={handleResetGame}
                        >
                            確定
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default ResetGameBtn;
