// MapPage.tsx

import Map from "../features/map/Map";
import { MapProvider } from "../features/map/MapContext";

const trainMap = () => {
    return (
        <MapProvider>
            <Map />
        </MapProvider>
    );
};

export default trainMap;
