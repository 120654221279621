// MapLocate.tsx
import React, { useContext, useEffect } from "react";

import L, { circle, Icon, LeafletMouseEvent, Map } from "leaflet";
import { useMap, useMapEvents } from "react-leaflet";

import UserIcon from "../../assets/images/user-location-icon.png";
import MapContext from "./MapContext";

const myLocationIcon = new L.Icon({
    iconUrl: UserIcon,
    iconSize: [52, 92],
});

let marker: L.Marker | null = null;
let accuracyCircle: L.Circle | null = null;

const MapLocate: React.FC = () => {
    const context = useContext(MapContext);

    if (!context) {
        throw new Error("MapLocate must be used within a MapProvider");
    }
    const { setMap, shouldFlyToUser, setShouldFlyToUser } = context;
    const map = useMap();
    useEffect(() => {
        if (map) {
            setMap(map);
        }
    }, [map, setMap]);

    useMapEvents({
        load: () => {
            console.log("map locate");
        },
        locationfound: (e) => {
            if (marker) {
                map.removeLayer(marker);
            }
            if (accuracyCircle) {
                map.removeLayer(accuracyCircle);
            }
            marker = L.marker(e.latlng, { icon: myLocationIcon }).addTo(map);
            accuracyCircle = L.circle(e.latlng, {
                radius: e.accuracy,
                fillColor: "#2985ef",
                fillOpacity: 0.1,
            }).addTo(map);

            if (shouldFlyToUser) {
                map.flyTo(e.latlng);
                setShouldFlyToUser(false);
            }
        },
        locationerror: () => {
            alert("無法取得使用者位置，請開啟權限並重新整理頁面");
        },
    });

    // useEffect(() => {
    //     map.locate();
    //     if (map) {
    //         const intervalId = setInterval(() => {
    //             map.locate();
    //         }, 5000);

    //         return () => {
    //             clearInterval(intervalId);
    //         };
    //     }
    // }, [map]);

    return null;
};

export default MapLocate;

// =========== ==========
// import { useEffect } from "react";

// import L from "leaflet";
// import { useMapEvents } from "react-leaflet";

// import UserIcon from "../../assets/images/user-location-icon.png";

// interface IProp {
//     flyToUser: boolean;
//     // setFlyToUser: () => void;
// }

// function MapLocate({ flyToUser }: IProp) {
//     let accuracyCircle: any = null;
//     let marker: any = null;

//     const map = useMapEvents({
//         locationfound: (location) => {
//             // setFlyToUser();
//             // 為了避免圓型和 Icon 重複渲染，所以需要先移除讓它初始化
//             if (accuracyCircle !== null) {
//                 accuracyCircle.remove();
//             }
//             if (marker !== null) {
//                 marker.remove();
//             }

//             const myLocationIcon = L.icon({
//                 iconUrl: UserIcon,
//                 iconSize: [52, 92],
//             });

//             marker = L.marker(location.latlng, {
//                 icon: myLocationIcon,
//             }).addTo(map);

//             accuracyCircle = L.circle(location.latlng, {
//                 radius: location.accuracy,
//                 fillColor: "#2985ef",
//                 fillOpacity: 0.1,
//             }).addTo(map);

//             if (flyToUser) {
//                 map.flyTo(location.latlng, map.getZoom());
//                 // setFlyToUser(false);
//             }
//         },
//         locationerror: () => {
//             alert("無法取得使用者位置，請開啟權限並重新整理頁面");
//         },
//     });

//     useEffect(() => {
//         map.locate();
//         // 定期調用 map.locate()
//         const intervalId = setInterval(() => {
//             map.locate();
//         }, 5000); // 調整時間間隔為你需要的值

//         // 在組件卸載時清除定時器
//         return () => {
//             clearInterval(intervalId);
//         };
//     }, [map]); // 如果 map 物件改變，會重啟定時器

//     return null;
// }

// export default MapLocate;
