import { createTheme } from "@mui/material/styles";

// Create a theme instance.
const theme = createTheme({
    palette: {
        primary: {
            main: "#d08e62",
        },
        secondary: {
            main: "#ec7700",
        },
    },
});

export default theme;
