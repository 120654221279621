import { apiSlice } from "../../services/api/apliSlice";
import { ILoginReq, ILoginRes } from "./interfaces";

//import { LoginReq, RegisterReq } from "../types/interfaces";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        googleLogin: builder.mutation<ILoginRes, ILoginReq>({
            query: (credential) => ({
                url: "/google_signup",
                method: "POST",
                body: { ...credential },
            }),
            invalidatesTags: ["Auth"],
        }),
        //checkUserStatus: builder.mutation<IUserStatusRes, void>({
        //    query: () => ({
        //        url: "/check_user_status",
        //        method: "POST",
        //    }),
        //    invalidatesTags: ["Auth"],
        //}),
    }),
});

export const { useGoogleLoginMutation } = authApiSlice;
