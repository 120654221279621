import styles from "./Map.module.scss";

import { useState } from "react";

import { Alert } from "@mui/material";
import { MapContainer, TileLayer, useMap } from "react-leaflet";

import Loader from "../../components/loader/Loader";
import MapMarker from "../../components/mapMarker/MapMarker";
import { useGetAllTrainsQuery } from "../train";
import MapLocation from "./MapLocate";

// import NavigateBtn from "./NavigateBtn";

const centralPos = { lat: 23.630711645888677, lng: 120.30992841175699 };

const Map = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [flyToUser, setFlyToUser] = useState<boolean>(false);

    const { data: allTrainsData, isLoading } = useGetAllTrainsQuery();

    const trains = allTrainsData?.status && allTrainsData?.data;

    return (
        <>
            <Loader isLoading={isLoading} />
            <section className={styles.container}>
                {open ? (
                    <Alert severity='error'>
                        請確保您的手機定位系統已開啟{" "}
                        <button onClick={() => setOpen(false)}>
                            OK 我知道了
                        </button>
                    </Alert>
                ) : (
                    <MapContainer
                        center={centralPos}
                        zoom={13}
                        scrollWheelZoom={true}
                        className={styles.map}
                        whenReady={() => console.log("props")}
                    >
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                        />
                        {trains && <MapMarker trains={trains} />}
                        <MapLocation />
                        {/* <NavigateBtn /> */}
                    </MapContainer>
                )}
            </section>
        </>
    );
};

export default Map;
