import styles from "../features/train/components/TrainList.module.scss";

import { Container } from "@mui/material";

import trainBg from "../assets/images/game_bg.png";
import { TrainList } from "../features/train";

const Game = () => {
    return (
        <>
            <Container className={styles.gameContainer}>
                <TrainList />
                <img
                    className={styles.bgTrainList}
                    src={trainBg}
                    alt='train_bg'
                />
            </Container>
            <div className={styles.mask}></div>
        </>
    );
};

export default Game;
