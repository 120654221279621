import styles from "./TrainList.module.scss";

import { useEffect, useState } from "react";

import Carousel3D from "../../../components/carousel3D/Carousel3D";
import Loader from "../../../components/loader/Loader";
import {
    useGetAllTrainsQuery,
    useGetMyCollectionsQuery,
} from "../trainApiSlice";

const TrainList = () => {
    const { data: allTrainsData, isLoading } = useGetAllTrainsQuery();
    const { data: collectionsData, isLoading: cLoading } =
        useGetMyCollectionsQuery();
    const [hideEl, setHideEl] = useState(false);

    let trains = allTrainsData?.status && allTrainsData?.data;
    const collections =
        (collectionsData?.status && collectionsData?.data) ?? [];

    const trainsCollections =
        trains &&
        collections &&
        trains.map((train) => {
            const isCollected = collections.includes(train.id);
            return { ...train, isCollected };
        });

    useEffect(() => {
        if (collectionsData?.data) {
            setHideEl(true);
        }
    }, []);

    return (
        <>
            <Loader isLoading={isLoading || cLoading} />
            {!hideEl ? (
                <>
                    <div className={styles.modal}>
                        <p>請點擊火車剪影，開啟相機進行互動</p>
                        <button onClick={() => setHideEl(true)}>確定</button>
                    </div>
                    <div className={styles.modalOverlay}></div>
                </>
            ) : (
                <></>
            )}
            <section className={styles.container}>
                {trainsCollections && (
                    <Carousel3D collections={trainsCollections} />
                )}
            </section>
        </>
    );
};

export default TrainList;
