import * as React from "react";
import { useEffect } from "react";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

interface Prop {
    isLoading: boolean;
}

const Loader = ({ isLoading }: Prop) => {
    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (isLoading) return;
        setOpen(false);
    }, [isLoading]);

    return (
        <div>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
                onClick={handleClose}
            >
                <CircularProgress color='inherit' />
            </Backdrop>
        </div>
    );
};

export default Loader;
