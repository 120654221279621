// MapContext.tsx
import React, { createContext, ReactNode, useEffect, useState } from "react";

import { LatLngExpression, Map } from "leaflet";
import { useMapEvents } from "react-leaflet";

interface MapContextProps {
    map: Map | null;
    setMap: (map: Map | null) => void;
    // flyToUser: () => void;
    shouldFlyToUser: boolean;
    setShouldFlyToUser: (shouldFlyToUser: boolean) => void;
}
const MapContext = createContext<MapContextProps | null>(null);

export const MapProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [map, setMap] = useState<Map | null>(null);
    const [shouldFlyToUser, setShouldFlyToUser] = useState(true);
    // const flyToUser = () => {
    //     if (map) {
    //         map.locate();
    //         map.flyTo(map.getCenter());
    //     }
    // };

    useEffect(() => {
        if (map) {
            map.locate();
            const intervalId = setInterval(() => {
                map.locate();
            }, 5000);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [map]);

    return (
        <MapContext.Provider
            value={{
                map,
                setMap,
                // flyToUser,
                shouldFlyToUser,
                setShouldFlyToUser,
            }}
        >
            {children}
        </MapContext.Provider>
    );
};

export default MapContext;
