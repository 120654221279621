import { apiSlice } from "../../services/api/apliSlice";
import { IApiRes } from "../../types/interfaces";
import { ICollectedTrainsRes, IPostCollectReq, ITrainData } from "./interface";

export const trainApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllTrains: builder.query<ITrainData, void>({
            query: () => ({
                url: "/list_all_trains",
            }),
            providesTags: ["Train"],
        }),

        getMyCollections: builder.query<ICollectedTrainsRes, void>({
            query: () => ({
                url: "/my_collected_trains",
            }),
            providesTags: ["Collection"],
        }),
        postCollect: builder.mutation<IApiRes, IPostCollectReq>({
            query: (reqData) => ({
                url: "/collect_this_train",
                method: "POST",
                body: { ...reqData },
            }),
            invalidatesTags: ["Collection"],
        }),
        resetGame: builder.mutation<IApiRes, void>({
            query: () => ({
                url: "/reset_my_record",
                method: "POST",
            }),
            invalidatesTags: ["Collection"],
        }),
    }),
});

export const {
    useGetAllTrainsQuery,
    usePostCollectMutation,
    useGetMyCollectionsQuery,
    useResetGameMutation,
} = trainApiSlice;
