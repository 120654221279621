import styles from "./MapMarker.module.scss";

import { useState } from "react";

import { IoCloseSharp } from "react-icons/io5";
import { Marker, Popup } from "react-leaflet";

import { ITrainInfo } from "../../features/train/interface";

interface IProps {
    trains: ITrainInfo[];
}
const MapMarker = ({ trains }: IProps) => {
    const [srcPath, setSrcPath] = useState("");
    return (
        <>
            {trains?.map((train, index) => {
                const locationPath = `${process.env.PUBLIC_URL}/images/train_location_${train.id}.jpg`;
                return (
                    <div key={train.id}>
                        <Marker
                            position={{
                                lat: Number(train.latitude),
                                lng: Number(train.longitude),
                            }}
                        >
                            <Popup className={styles["leaflet-popup-content"]}>
                                <img
                                    src={locationPath}
                                    alt={`train${train.id}`}
                                    onClick={() => setSrcPath(locationPath)}
                                />
                            </Popup>
                        </Marker>
                    </div>
                );
            })}
            {srcPath ? (
                <div className={styles.trainBigImage}>
                    <IoCloseSharp onClick={() => setSrcPath("")} />
                    <img src={srcPath} alt='train' />
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default MapMarker;
