import styles from "./Login.module.scss";

import { useState } from "react";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Button, Container, Typography } from "@mui/material";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

import logo from "../../../../assets/images/logo.png";
import trainPanel from "../../../../assets/images/panel.png";
import Loader from "../../../../components/loader/Loader";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import { apiSlice } from "../../../../services/api/apliSlice";
import {
    signOut,
    socialLoginHandler,
} from "../../../../services/firebase/firebaseAuth";
import { SocialLoginType } from "../../../../types/enum";
import { useGoogleLoginMutation } from "../../authApiSlice";
import {
    postLogin,
    postLogout,
    selectIsLogin,
    setCurrentUser,
} from "../../authSlice";
import { ILoginReq } from "../../interfaces";
import TutorialModal from "./TutorialModal";

const Login = () => {
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(true);

    const dispatch = useAppDispatch();

    const [fetchGoogleSignIn, { isLoading: googleLoading }] =
        useGoogleLoginMutation();

    const isLogin = useAppSelector(selectIsLogin);

    const handleFetchSignIn = (loginType: string, userInfo: ILoginReq) => {
        switch (loginType) {
            case SocialLoginType.google:
                return () => fetchGoogleSignIn({ ...userInfo });

            default:
                throw new Error("Unknown login type: " + loginType);
        }
    };
    const handleSocialLogin = async (loginType: string) => {
        try {
            const userResult = await socialLoginHandler(loginType);
            if (!userResult) return;
            const accessToken = userResult.accessToken;

            const userInfo = {
                firebase_uid: userResult.uid,
                email: userResult.email!,
                username: userResult.displayName!,
                photo_url: userResult.photoURL!,
            };
            console.log("🐑 ~ accessToken:", {
                ...userInfo,
                access_token: accessToken,
            });
            const res = await handleFetchSignIn(loginType, {
                ...userInfo,
                access_token: accessToken,
            })().unwrap();

            if (res?.status && res?.data) {
                const { account_id, access_token, refresh_token } = res?.data;
                dispatch(
                    setCurrentUser({
                        username: userInfo.username,
                        email: userInfo.email,
                        photoUrl: userInfo.photo_url,
                        accountId: account_id,
                    })
                );
                dispatch(
                    postLogin({
                        accessToken: access_token,
                        refreshToken: refresh_token,
                        accountId: account_id,
                    })
                );
                navigate("/game");
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleLogout = async () => {
        await signOut();
        dispatch(postLogout());
        dispatch(apiSlice.util.resetApiState());
        navigate("/login", { replace: true });
        return;
    };

    return (
        <Container
            component='section'
            className={clsx([styles.container, styles.bg])}
        >
            <Loader isLoading={googleLoading} />
            <div>
                <div className={styles.logoBox}>
                    <img src={logo} alt='logo' />
                </div>
                <div className={styles.content}>
                    <div>
                        <Typography variant='h3' mt={1}>
                            遊戲說明 INSTRUCTIONS
                        </Typography>
                        <Typography mt={1} textAlign='justify'>
                            開啟手機相機掃描火車金牌上的 QR-code
                            即可進入遊戲，登入帳號後就可以開始尋寶囉!
                            在扇步道上共有12輛火車等待你來解鎖，你可以開啟地圖尋找火車金牌的位置，找到金牌點擊遊戲圖鑑中對應的火車就會自動開啟相機，對準火車金牌拍下照片即可成功解鎖。
                        </Typography>
                        {window.innerWidth < 700 ? (
                            <></>
                        ) : (
                            <Typography
                                mt={1}
                                textAlign='justify'
                                lineHeight='1.2'
                                fontSize='14px'
                            >
                                Turn on your phone camera and scan the QR code
                                on the train's metal plate to enter the game.
                                Log in to your account and start treasure
                                hunting! A total of 12 trains on the trails
                                alongside the fan-shaped garage cluster are
                                waiting for you to unlock. You can find the
                                location of a train's metal plate on the map.
                                Click on the corresponding train in the photo
                                gallery to automatically open the camera, and
                                then take a picture of the train's metal plate
                                to unlock it successfully.
                            </Typography>
                        )}
                    </div>
                    <div className={styles.btnBox}>
                        <img src={trainPanel} alt='' />
                        <Button
                            className={styles.signBtn}
                            variant='contained'
                            size='large'
                            onClick={() => {
                                return isLogin
                                    ? handleLogout()
                                    : handleSocialLogin(SocialLoginType.google);
                            }}
                        >
                            {isLogin ? "登出 SIGN OUT" : "登入 SIGN IN"}
                        </Button>
                    </div>
                </div>
            </div>
            <button
                className={styles.tutorialBtn}
                title='tutorial'
                onClick={() => setIsOpen(true)}
            >
                <HelpOutlineIcon />
            </button>
            <TutorialModal isOpen={isOpen} setIsOpen={() => setIsOpen(false)} />
        </Container>
    );
};
export default Login;
