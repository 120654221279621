import styles from "./FullscreenBtn.module.scss";

import { useState } from "react";

import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { Button } from "@mui/material";

const getBrowserFullscreenElementProp = () => {
    if (typeof document.fullscreenElement !== "undefined") {
        return "fullscreenElement";
    } else if (typeof document.mozFullScreenElement !== "undefined") {
        return "mozFullScreenElement";
    } else if (typeof document.msFullscreenElement !== "undefined") {
        return "msFullscreenElement";
    } else if (typeof document.webkitFullscreenElement !== "undefined") {
        return "webkitFullscreenElement";
    } else {
        throw new Error("fullscreenElement is not supported by this browser");
    }
};

const FullscreenBtn = () => {
    const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

    const handleFullScreen = async () => {
        try {
            const elProp = await getBrowserFullscreenElementProp();
            if (!document[elProp]) {
                await document.querySelector("body")?.requestFullscreen();
                setIsFullscreen(true);
            } else {
                document.exitFullscreen();
                setIsFullscreen(false);
            }
        } catch (err) {
            // 直接在不支援的裝置上不顯示全螢幕按鈕
            alert("not Support");
        }
    };

    return (
        <Button
            className={styles.fullscreenBtn}
            variant='text'
            onClick={() => handleFullScreen()}
        >
            {isFullscreen ? (
                <FullscreenExitIcon fontSize='large' />
            ) : (
                <FullscreenIcon fontSize='large' />
            )}
        </Button>
    );
};

export default FullscreenBtn;
