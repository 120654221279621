import styles from "./Header.module.scss";

import { useState } from "react";

import clsx from "clsx";
import useSound from "use-sound";

import menuSound from "../../assets/audio/hamburger.mp3";
import Hamburger from "../hamburger/Hamburger";

const Header = () => {
    const [open, setOpen] = useState(false);
    const [play] = useSound(menuSound);

    const handleOpen = () => {
        setOpen(!open);
        play();
    };

    return (
        <header className={clsx([styles.header, open ? styles.fullWidth : ""])}>
            <Hamburger onOpenMenu={handleOpen} menuOpen={open} />
        </header>
    );
};

export default Header;
