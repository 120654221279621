import styles from "./Hamburger.module.scss";

import { Button, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { selectIsLogin } from "../../features/authentication/authSlice";
import { ResetGameBtn } from "../../features/train";
import { useAppSelector } from "../../hooks/reduxHooks";
import { IPath } from "../../types/interfaces";

interface Prop {
    menuOpen: boolean;
    onOpenMenu: () => void;
}

const pathArr: IPath[] = [
    { url: "/game", name: "尋寶圖鑑" },
    { url: "/map", name: "尋寶地圖" },
    { url: "/collections", name: "卡牌" },
    { url: "/login", name: "遊戲說明" },
];

const Hamburger = ({ onOpenMenu, menuOpen }: Prop) => {
    const isLogin = useAppSelector(selectIsLogin);
    const navigate = useNavigate();

    const handleClose = () => onOpenMenu();

    const handleClickMethod = (url: string) => navigate(url);

    if (!isLogin) {
        return <></>;
    }

    return (
        <Container>
            <nav>
                {menuOpen ? (
                    <>
                        {pathArr.map((item) => (
                            <div
                                className={
                                    item.name === "卡牌" ? styles.textSpace : ""
                                }
                                key={item.name}
                                onClick={() => {
                                    handleClickMethod(item.url);
                                }}
                            >
                                {item.name}
                            </div>
                        ))}
                        <ResetGameBtn confirmCb={handleClose} />
                    </>
                ) : (
                    <></>
                )}

                <Button
                    className={styles.hamburger}
                    variant='outlined'
                    id='basic-button'
                    aria-haspopup='true'
                    onClick={onOpenMenu}
                >
                    <div className={styles.topLine}></div>
                    <div className={styles.midLine}></div>
                    <div className={styles.btmLine}></div>
                </Button>
            </nav>
        </Container>
    );
};

export default Hamburger;
