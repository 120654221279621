import styles from "./Carousel3D.module.scss";

import { useEffect, useMemo, useState } from "react";

import { AnimatePresence, motion } from "framer-motion";
import _ from "lodash";
import { useSearchParams } from "react-router-dom";
import useSound from "use-sound";

import slideSound from "../../assets/audio/carousel.wav";
import { selectCurrentUser } from "../../features/authentication/authSlice";
import {
    useGetMyCollectionsQuery,
    usePostCollectMutation,
} from "../../features/train";
import { ITrainInfo } from "../../features/train/interface";
import { useAppSelector } from "../../hooks/reduxHooks";
import Loader from "../loader/Loader";

const arUrl = process.env.REACT_APP_8thWall_URL;

function getZIndex({
    position,
    direction,
}: {
    position: () => string;
    direction: number;
}) {
    interface Indexes {
        [key: string]: number;
    }
    const indexes: Indexes = {
        left: direction > 0 ? 2 : 1,
        center: 3,
        right: direction > 0 ? 1 : 2,
    };

    return indexes[position()];
}

const variants = {
    enter: (direction: number) => {
        return { scale: 0.2, x: direction < 1 ? 50 : -50, opacity: 0 };
    },
    center: ({
        position,
        direction,
    }: {
        position: () => string;
        direction: number;
    }) => {
        return {
            scale: position() === "center" ? 1 : 0.5,
            x: 0,
            zIndex: getZIndex({ position, direction }),
            opacity: 1,
        };
    },
    exit: (direction: number) => {
        return { scale: 0.2, x: direction < 1 ? -50 : 50, opacity: 0 };
    },
};

interface ITrainCollection extends ITrainInfo {
    isCollected: boolean;
}

interface Prop {
    collections: ITrainCollection[];
}

const Carousel3D = ({ collections }: Prop) => {
    const collectionLength = collections?.length;
    const [searchParams] = useSearchParams();
    const collectTrainId = searchParams.get("collectTrainId");
    let trainPosition = searchParams.get("trainPosition");

    const currentUser = useAppSelector(selectCurrentUser);

    //activeIndex 值為 center
    const [[activeIndex, direction], setActiveIndex] = useState([-1, 0]);

    // ========== api ==========
    const [postCollect, { error }] = usePostCollectMutation();
    const { data: collectionsData, isLoading } = useGetMyCollectionsQuery();
    const [play] = useSound(slideSound);
    const userCollectedTrain = useMemo(
        () => (collectionsData?.status && collectionsData?.data) ?? [],
        [collectionsData?.status, collectionsData?.data]
    );

    // ========== carousel helper ==========
    // we want the scope to be always to be in the scope of the array so that the carousel is endless
    const indexInArrayScope =
        ((activeIndex % collectionLength) + collectionLength) %
        collectionLength;

    const sliceEnd =
        collectionLength < 3
            ? indexInArrayScope + collectionLength
            : indexInArrayScope + 3;
    // so that the carousel is endless, we need to repeat the items twice
    // then, we slice the the array so that we only have 3 items visible at the same time
    const visibleItems = [...collections, ...collections].slice(
        indexInArrayScope,
        sliceEnd
    );

    const debounce = _.debounce((btnType) => {
        if (btnType === "prev") {
            handleClick(-1);
        } else if (btnType === "next") {
            handleClick(1);
        }
        play();
    }, 250);

    // ========== event handler ==========
    const handleClick = (newDirection: any) => {
        setActiveIndex((prevIndex) => [
            prevIndex[0] + newDirection,
            newDirection,
        ]);
        // 將 webAR 的值拿回來直接設定 activeIndex
    };
    const redirectToAR = async (trainId: string) => {
        const url =
            arUrl +
            `?train_ids=${JSON.stringify(userCollectedTrain)}&accountId=${
                currentUser?.accountId
            }`;
        window.location.href = url;
    };

    // ========== useEffect ==========

    useEffect(() => {
        if (error) {
            console.log(error); // TODO: handle show error UI, [optional]
        }
    }, [error]);

    useEffect(() => {
        const fetchCollect = async (collectTrainId: string) => {
            try {
                await postCollect({
                    train_id: collectTrainId,
                    account_id: currentUser?.accountId!,
                })
                    .unwrap()
                    .then((res) => {
                        // handle the response here
                        console.log("Response:", res);
                    });
            } catch (error) {
                // handle error here, the error is what's rejected from the mutation API call
                console.error("Failed to collect train:", error);
            }
        };
        if (
            collectTrainId &&
            userCollectedTrain &&
            !userCollectedTrain.includes(collectTrainId) &&
            currentUser?.accountId
        ) {
            fetchCollect(collectTrainId);
        }
    }, [
        collectTrainId,
        userCollectedTrain,
        postCollect,
        currentUser?.accountId,
    ]);

    useEffect(() => {
        if (!trainPosition) return;
        setActiveIndex([Number(trainPosition) - 2, 0]);
    }, [trainPosition]);

    return (
        <div className={styles["main-wrapper"]}>
            <Loader isLoading={isLoading} />
            <div className={styles.wrapper}>
                {/*AnimatePresence is necessary to show the items after they are deleted because only max. 3 are shown*/}
                <AnimatePresence mode='popLayout' initial={false}>
                    {visibleItems.map((item) => {
                        const lockedPath = `${process.env.PUBLIC_URL}/images/train_locked_${item.id}.png`;
                        const animationPath = `${process.env.PUBLIC_URL}/images/train_animation_${item.id}.gif`;
                        const staticPath = `${process.env.PUBLIC_URL}/images/train_static_${item.id}.png`;

                        // The layout prop makes the elements change its position as soon as a new one is added
                        // The key tells framer-motion that the elements changed its position

                        // 為了讓 img 的 src 可以根據位置渲染不同圖片，所以必須在此先宣告 position
                        const position = () => {
                            if (item === visibleItems[0]) {
                                return "left";
                            } else if (item === visibleItems[1]) {
                                return "center";
                            } else {
                                return "right";
                            }
                        };
                        const itemImgSrc = item.isCollected
                            ? position() === "center"
                                ? animationPath
                                : staticPath
                            : lockedPath;
                        return (
                            <motion.div
                                className={styles.card}
                                key={item.id}
                                layout
                                custom={{
                                    direction,
                                    position,
                                }}
                                variants={variants}
                                initial='enter'
                                animate='center'
                                exit='exit'
                                transition={{ duration: 1 }}
                            >
                                <img
                                    src={itemImgSrc}
                                    alt=''
                                    onClick={() => {
                                        if (!item.isCollected) {
                                            redirectToAR(item.id);
                                        }
                                    }}
                                />
                            </motion.div>
                        );
                    })}
                </AnimatePresence>
            </div>
            <div className={styles.buttons}>
                <motion.button
                    whileTap={{ scale: 0.8 }}
                    onClick={() => {
                        debounce("prev");
                    }}
                    className={styles.prevBtn}
                >
                    ◀︎
                </motion.button>
                <motion.button
                    whileTap={{ scale: 0.8 }}
                    onClick={() => {
                        debounce("next");
                    }}
                    className={styles.nextBtn}
                >
                    ▶︎
                </motion.button>
            </div>
        </div>
    );
};

export default Carousel3D;
