import styles from "./Collections.module.scss";

import { useState } from "react";

import { Container } from "@mui/material";
import useSound from "use-sound";

import flipSound from "../../assets/audio/flip_card.wav";
import Loader from "../../components/loader/Loader";
import { ITrainInfo } from "../train/interface";
import {
    useGetAllTrainsQuery,
    useGetMyCollectionsQuery,
} from "../train/trainApiSlice";
import CollectionCard from "./CollectionCard";

const Collections = () => {
    const [play] = useSound(flipSound);
    const { data: allTrainsData, isLoading } = useGetAllTrainsQuery();
    const { data: collectionsData, isLoading: cLoading } =
        useGetMyCollectionsQuery();
    let trains = allTrainsData?.status && allTrainsData?.data;
    const collections =
        (collectionsData?.status && collectionsData?.data) ??
        [
            // 加入 '01'，'01' 圖會被收集
        ];
    const [info, setInfo] = useState<ITrainInfo | null>(null);

    const handleClick = (selectedTrain: ITrainInfo) => {
        setInfo(selectedTrain);
        play();
    };

    // TODO:  challenge to build custom hook for this component
    return (
        <>
            <Loader isLoading={isLoading || cLoading} />
            <Container component='section' className={styles.container}>
                {info && <CollectionCard collectionInfo={info} />}
                <ul
                    className={`${styles.collections} ${styles.collectionsContainer}`}
                >
                    {trains &&
                        collections &&
                        trains.map((train) => {
                            const isCollected = collections.includes(train.id);
                            const filePath = `${process.env.PUBLIC_URL}/images/collection_thumbnail_${train.id}.png`;
                            return (
                                <li
                                    className={`${styles.collection} ${
                                        isCollected ? "" : styles.relative
                                    }`}
                                    key={train.id}
                                >
                                    <img
                                        className={
                                            isCollected ? "" : styles.opacity50
                                        }
                                        src={filePath}
                                        alt=''
                                        onClick={() => handleClick(train)}
                                    />
                                    <div
                                        className={
                                            isCollected ? "" : styles.blockEl
                                        }
                                    ></div>
                                </li>
                            );
                        })}
                </ul>
            </Container>
        </>
    );
};

export default Collections;
