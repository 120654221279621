import { Navigate, Outlet } from "react-router-dom";

import Loader from "../../../components/loader/Loader";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { selectIsAuth, selectIsLogin } from "../authSlice";

const PrivateLayout = () => {
    const isLogin = useAppSelector(selectIsLogin);
    const isAuth = useAppSelector(selectIsAuth);

    if (!isLogin) {
        console.log("private Layout failed");
        return isAuth === "loading" ? (
            <Loader isLoading={true} />
        ) : (
            <Navigate to='/' replace />
        );
    }
    return (
        <div>
            <Outlet />
        </div>
    );
};
export default PrivateLayout;
