import styles from "./Login.module.scss";

import React, { useState } from "react";

import { Box, Tab, Tabs, Typography } from "@mui/material";
import clsx from "clsx";
import { IoCloseSharp } from "react-icons/io5";
import { SiGooglechrome, SiSafari } from "react-icons/si";

import tutorialImg from "../../../../assets/images/全螢幕說明2.jpg";
import {
    googleLocationAuth,
    googleLocationSettings,
    googleLocationToggle,
    safariAddHome,
    safariLocationAllow,
    safariLocationAuth,
    safariLocationService,
    safariLocationToggle,
    safariPrecise,
    safariPrivacy,
    safariSaveHome,
    safariShare,
    safariToolbar,
    safariWebSettings,
} from "./TutorialImages";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface Prop {
    isOpen: boolean;
    setIsOpen: () => void;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ py: "12px", height: "30vh" }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
const TutorialModal = ({ isOpen, setIsOpen }: Prop) => {
    const [value, setValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
        // <>
        //     <div
        //         className={clsx([styles.modal, isOpen ? "" : styles.modalFold])}
        //     >
        //         <h2>
        //             <span>手機基本設定教學</span>
        //             <IoCloseSharp onClick={setIsOpen} />
        //         </h2>
        //         <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        //             <Tabs
        //                 value={value}
        //                 onChange={handleChange}
        //                 aria-label='basic tabs example'
        //             >
        //                 <Tab
        //                     icon={<SiSafari size='24px' />}
        //                     label='Safari'
        //                     {...a11yProps(0)}
        //                 />
        //                 <Tab
        //                     icon={<SiGooglechrome size='24px' />}
        //                     label='Google'
        //                     {...a11yProps(1)}
        //                 />
        //             </Tabs>
        //         </Box>
        //         <div className={styles.overflow}>
        //             <TabPanel value={value} index={0}>
        //                 <h3>開啟定位</h3>
        //                 <p>
        //                     &#x2022; 點選『設定』，打開隱私權與安全性。
        //                     <img src={safariPrivacy} alt='privacy' />
        //                 </p>
        //                 <p>
        //                     &#x2022; 點選『定位服務』，並同步開啟。
        //                     <img
        //                         src={safariLocationService}
        //                         alt='safariLocationService'
        //                     />
        //                     <img
        //                         src={safariLocationToggle}
        //                         alt='safariLocationToggle'
        //                     />
        //                 </p>
        //                 <p>
        //                     &#x2022;
        //                     向下滑動，找到Safari網站勾選『使用App期間』，並開啟『準確位置』。
        //                     <img
        //                         src={safariLocationAuth}
        //                         alt='safariLocationAuth'
        //                     />
        //                     <img src={safariPrecise} alt='safariPrecise' />
        //                 </p>

        //                 <p>
        //                     &#x2022; 點選網址欄位『大小』字樣。
        //                     <img src={safariToolbar} alt='safariToolbar' />
        //                 </p>

        //                 <p>
        //                     &#x2022; 點選『網站設定』。
        //                     <img
        //                         src={safariWebSettings}
        //                         alt='safariWebSettings'
        //                     />
        //                 </p>

        //                 <p>
        //                     &#x2022; 點擊位置，勾選『允許』。
        //                     <img
        //                         src={safariLocationAllow}
        //                         alt='safariLocationAllow'
        //                     />
        //                 </p>
        //                 {/* <hr /> */}
        //                 <h3>開啟全螢幕</h3>
        //                 <p>
        //                     &#x2022; 查看網頁上/下方欄位，點選該圖示！{" "}
        //                     <img src={safariShare} alt='safariShare' />
        //                 </p>
        //                 <p>
        //                     &#x2022; 向下滑動，點選『加入主畫面』。
        //                     <img src={safariAddHome} alt='safariAddHome' />
        //                 </p>

        //                 <p>
        //                     &#x2022;
        //                     右上角點選新增，該圖示即可在主畫面浮現出，點選該圖示即可成功進入!
        //                     <img src={safariSaveHome} alt='safariSaveHome' />
        //                 </p>
        //             </TabPanel>
        //             <TabPanel value={value} index={1}>
        //                 <h3>開啟定位</h3>
        //                 <p>
        //                     &#x2022; 點選『設定』，找到位置，開啟『位置資訊』。
        //                     <img
        //                         src={googleLocationSettings}
        //                         alt='googleLocationSettings'
        //                     />
        //                     <img
        //                         src={googleLocationToggle}
        //                         alt='googleLocationToggle'
        //                     />
        //                 </p>
        //                 <p>
        //                     &#x2022; 開啟『位置權限』，同步開啟『精確位置』。
        //                     <img
        //                         src={googleLocationAuth}
        //                         alt='googleLocationAuth'
        //                     />
        //                 </p>
        //             </TabPanel>
        //         </div>
        //     </div>
        //     <div
        //         className={clsx([
        //             styles.modalOverlay,
        //             isOpen ? "" : styles.hide,
        //         ])}
        //     ></div>
        // </>
        <>
            {isOpen ? (
                <div className={styles.tutorialBox}>
                    <IoCloseSharp onClick={setIsOpen} />
                    <img src={tutorialImg} alt='' />
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default TutorialModal;
