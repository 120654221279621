import styles from "./HomePage.module.scss";

import { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import homeBg from "../assets/videos/home_train.mp4";
import { selectIsLogin } from "../features/authentication/authSlice";
import { useAppSelector } from "../hooks/reduxHooks";

const HomePage = () => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const navigate = useNavigate();
    const isLogin = useAppSelector(selectIsLogin);
    const [hideEl, setHideEl] = useState(false);

    const handleVideo = () => {
        if (videoRef.current) {
            videoRef.current.play();
            setHideEl(true);
        }
    };

    useEffect(() => {
        if (isLogin) {
            navigate("/game");
        }
    }, [isLogin, navigate]);

    useEffect(() => {
        const video = videoRef.current;
        const handleVideoEnd = () => {
            navigate("/login");
        };

        video?.addEventListener("ended", handleVideoEnd);

        // 移除事件監聽器以避免記憶體洩漏
        return () => {
            video?.removeEventListener("ended", handleVideoEnd);
        };
    }, []);

    return (
        <>
            <div>
                <video
                    ref={videoRef}
                    src={homeBg}
                    width='100%'
                    preload='auto'
                    autoPlay
                    playsInline
                ></video>
            </div>
            {hideEl ? (
                <></>
            ) : (
                <>
                    <div className={styles.modal}>
                        <p>將螢幕改成橫式能有最好的使用體驗哦！</p>
                        <button onClick={handleVideo}>確定</button>
                    </div>
                    <div className={styles.modalOverlay}></div>
                </>
            )}
        </>
    );
};
export default HomePage;
